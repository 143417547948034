<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <form action="#" v-if="vmPayTransactionsFormData && Object.keys(vmPayTransactionsFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationpay_id">
                    {{cvPayIdLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_id"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_order_id">
                    {{cvPayOrderIdLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_order_id"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_status_code">
                    {{cvPayStatusCodeLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_status_code"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_status_desc">
                    {{cvPayStatusDescLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_status_desc"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_amount">
                    {{cvPayAmountLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_amount"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_currency">
                    {{cvPayCurrencyLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_currency"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_tax">
                    {{cvPayTaxLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_tax"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_subs_id">
                    {{cvPaySubsIdLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_subs_id"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_admin_bill_id">
                    {{cvPayBillingDetailsIdLabel}}</label>
                  <input
                    v-model="vmPayTransactionsFormData.pay_admin_bill_id"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpay_params">
                    {{cvPayParamsLabel}}</label>
                  <textarea
                    v-model="vmPayTransactionsFormData.pay_params"
                    type="text"
                    class="form-control"
                    required
                  ></textarea>
                </div>
              </div>
            <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="payTransactionsEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { PayTransactionss } from "../../../FackApi/api/PayTransactions"
import ApiResponse from "../../../Utils/apiResponse.js"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "PayTransactionsEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propPayTransactionsObj: {
      type: Object,
      default: function () {
        return {
          "pay_id": "",
          "pay_order_id": "",
          "pay_status_code": "",
          "pay_status_desc": "",
          "pay_amount": "",
          "pay_currency": "",
          "pay_tax": "",
          "pay_subs_id": "",
          "pay_admin_bill_id": "",
          "pay_params": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "payTransactions_edit",
      cvCardTitle: "Edit Pay Transactions",
      cvCardSubHeader: "Edit Pay Transactions",
      cvSubmitBtn: "Edit",
      cvPayIdLabel: "pay id",
      cvPayOrderIdLabel: "pay order id",
      cvPayStatusCodeLabel: "pay status code",
      cvPayStatusDescLabel: "pay status desc",
      cvPayAmountLabel: "pay amount",
      cvPayCurrencyLabel: "pay currency",
      cvPayTaxLabel: "pay tax",
      cvPaySubsIdLabel: "pay subs id",
      cvPayBillingDetailsIdLabel: "pay billing details id",
      cvPayParamsLabel: "pay params",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "PayTransactions",
      vmPayTransactionsFormData: {}
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.payTransactionsView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmPayTransactionsFormData) {
          if (!this.vmPayTransactionsFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * payTransactionsView
     */
    async payTransactionsView () {
      try {
        if (this.propOpenedInModal) {
          this.vmPayTransactionsFormData = this.propPayTransactionsObj
        }
        else {
          let payTransactionsId = this.$route.params.pay_id
          let payTransactionsViewResp = await PayTransactionss.payTransactionsView(this, payTransactionsId)
          if (payTransactionsViewResp && payTransactionsViewResp.resp_status) {
            this.vmPayTransactionsFormData = payTransactionsViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at payTransactionsView() and Exception:", err.message)
      }
    },
    /**
     * payTransactionsEdit
     */
    async payTransactionsEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let payTransactionsAddResp = await PayTransactionss.payTransactionsEdit(this, this.vmPayTransactionsFormData)
        await ApiResponse.responseMessageDisplay(this, payTransactionsAddResp)
        if (payTransactionsAddResp && !payTransactionsAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitClosePayTransactionsEditModal", this.vmPayTransactionsFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at payTransactionsEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
